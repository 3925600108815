import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import cx from "classnames"

import Container from "@components/elements/container"
import Wysiwyg from "@components/elements/wysiwyg"
import ContactForm from "@components/elements/contactForm"

import styles from "./contactSection.module.scss"

interface ContactSectionProps {
  title?: string
  text?: string
  locationLeft?: string
  locationRight?: string
  locationDc?: string
}

const ContactSection = ({
  title,
  text,
  locationLeft,
  locationRight,
  locationDc,
}: ContactSectionProps) => {
  return (
    <section className={styles.contactSection}>
      <Container className={styles.container}>
        {title && <h1 className={styles.title}>{title}</h1>}
        <Wysiwyg className={styles.text} contentHtml={text} />

        <ContactForm className={styles.form} />

        {locationLeft && (
          <div className={styles.locationText}>{locationLeft}</div>
        )}
        {locationRight && (
          <div className={styles.locationText}>{locationRight}</div>
        )}
        {locationDc && <div className={styles.locationText}>{locationDc}</div>}
      </Container>

      <div className={styles.bg}>
        <div className={styles.backgroundContainer}>
          <StaticImage
            className={styles.background}
            layout={"constrained"}
            src="../images/bg-map.jpg"
            placeholder="none"
            alt=""
          />
          <button
            className={cx(styles.backgroundDot, styles.isLeft, {
              [styles.hasContent]: locationLeft,
            })}
            type="button"
          >
            {locationLeft && (
              <div className={styles.backgroundDotContent}>{locationLeft}</div>
            )}
          </button>
          <button
            className={cx(styles.backgroundDot, styles.isRight, {
              [styles.hasContent]: locationRight,
            })}
            type="button"
          >
            {locationRight && (
              <div className={styles.backgroundDotContent}>{locationRight}</div>
            )}
          </button>
        </div>
      </div>
    </section>
  )
}

export default ContactSection
