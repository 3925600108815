import * as React from "react"
import { graphql } from "gatsby"

import Layout from "@components/layout/layout"
import ContactSection from "@components/contactSection"

const Contact = ({ data }: any) => {
  const {
    meta_title,
    meta_description,
    meta_image,
    location_left,
    location_right,
    location_dc,
  } = data.prismicContact.data

  return (
    <Layout
      headerLight
      title={meta_title}
      description={meta_description}
      metaImage={meta_image?.url}
    >
      <ContactSection
        title="Contact us"
        text="Reach out using the form below, or email <a href='mailto:info@phasecraft.io'>info@phasecraft.io</a>"
        locationLeft={location_left}
        locationRight={location_right}
        locationDc={location_dc}
      />
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    prismicContact {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        location_left
        location_right
        location_dc
      }
    }
  }
`
